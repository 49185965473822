import ReactDOM from 'react-dom/client';

import ReactGA from 'react-ga4';

import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';

// ----------------------------------------------------------------------

ReactGA.initialize(process.env.REACT_APP_GA4_ID);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<App />);

// If you want to enable client cache, register instead.
serviceWorker.unregister();
