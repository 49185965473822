const CATEGORIES = {
    PAGE_SHARING: 'page.sharing',
};

const ACTIONS = {
    REGION_WHATS_APP_CLICKED: 'region.whatsapp.clicked',
    REGION_TWITTER_CLICKED: 'region.twitter.clicked',
    REGION_FACEBOOK_CLICKED: 'region.facebook.clicked',
    REGION_LINKEDIN_CLICKED: 'region.linkedin.clicked',
    REGION_EMAIL_CLICKED: 'region.email.clicked',
    REGION_COPY_URL_CLICKED: 'region.copyurl.clicked',
    REGION_PRINT_CLICKED: 'region.print.clicked',            
};

export { CATEGORIES, ACTIONS };