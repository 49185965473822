import PropTypes from 'prop-types';

import { useState, useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { ContentCopy, PictureAsPdf } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';

import ReactGA from 'react-ga4';

import { useTranslation } from 'react-i18next';

import copy from 'copy-to-clipboard'; 

import { CATEGORIES, ACTIONS } from 'analytics/actions';

import {
    WhatsappShareButton,
    TwitterShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    EmailShareButton,
    WhatsappIcon,
    TwitterIcon,
    FacebookIcon,
    LinkedinIcon,
    EmailIcon,
  } from "react-share";

const headerSx = {
    display: 'flex',
    flexDirection: { xs: 'column', md: 'row' },
    alignItems: { md: 'center' },
    justifyContent: { xs: 'flex-start', md: 'space-between' },
};

const ButtonBox = ({ hint, children, analyticsCategory, analyticsAction, pl = 0.1, pr = 0.1 }) => (
    <Tooltip title={hint} arrow>
        <Box
            sx={{ pl, pr }}
            onClick={() => ReactGA.event({
                category: analyticsCategory,
                action: analyticsAction,
            })}
        >
            {children}
        </Box>
    </Tooltip>
);

ButtonBox.propTypes = {
    hint: PropTypes.string,
    children: PropTypes.node,
    analyticsCategory: PropTypes.string,
    analyticsAction: PropTypes.string,
    pl: PropTypes.number,
    pr: PropTypes.number,
};

const RoundButton = ({ onClick, children }) => {

    const theme = useTheme();

    return (
        <IconButton
            sx={{
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                    backgroundColor: theme.palette.primary.main,
                },
                color: 'white',
                width: 32,
                height: 32,
            }}
            onClick={onClick}
        >
            {children}
        </IconButton>
    );
};
    
RoundButton.propTypes = {
    onClick: PropTypes.func,
    children: PropTypes.node,
};
  
const RegionTitleHeader = ({ regionName }) => {

    const { t } = useTranslation();
    const theme = useTheme();

    const location = useLocation();
    const { protocol } = window.location;
    const { host } = window.location;
    const currentUrl = `${protocol}//${host}${location.pathname}${location.search}${location.hash}`;

    const [pageTitle, setPageTitle] = useState(document.title);
    
    const copyTooltipReady = t('regionHeader.tooltipCopyLink');
    const copyTooltipDone = t('regionHeader.tooltipCopyLinkDone');
    const [copyTooltip, setCopyTooltip] = useState(copyTooltipReady);

    const setTooltipDone = () => {
        setCopyTooltip(copyTooltipDone);
        setTimeout(() => setCopyTooltip(copyTooltipReady), 2000)
    };

    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    setPageTitle(document.title);
                }
            });
        });
    
        const titleElement = document.querySelector('title');
        if (titleElement) {
            observer.observe(titleElement, { childList: true });
        }
    
        return () => {
            observer.disconnect();
        };
    }, []);
    
    return (
        <Box sx={headerSx}>
            <Typography variant="h3" sx={{ textTransform: 'capitalize' }}>
                {regionName}
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                <ButtonBox
                    hint={t('regionHeader.tooltipShareOnWhatsapp')}
                    analyticsCategory={CATEGORIES.PAGE_SHARING}
                    analyticsAction={ACTIONS.REGION_WHATS_APP_CLICKED}
                >
                    <WhatsappShareButton url={currentUrl} title={pageTitle}>
                        <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                </ButtonBox>
                <ButtonBox
                    hint={t('regionHeader.tooltipShareOnTwitter')}
                    analyticsCategory={CATEGORIES.PAGE_SHARING}
                    analyticsAction={ACTIONS.REGION_TWITTER_CLICKED}
                >
                    <TwitterShareButton url={currentUrl} title={pageTitle}>
                        <TwitterIcon size={32} round />
                    </TwitterShareButton>
                </ButtonBox>
                <ButtonBox
                    hint={t('regionHeader.tooltipShareOnFacebook')}
                    analyticsCategory={CATEGORIES.PAGE_SHARING}
                    analyticsAction={ACTIONS.REGION_FACEBOOK_CLICKED}
                >
                    <FacebookShareButton url={currentUrl}>
                        <FacebookIcon size={32} round />
                    </FacebookShareButton>
                </ButtonBox>
                <ButtonBox
                    hint={t('regionHeader.tooltipShareOnLinkedIn')}
                    pr={2}
                    analyticsCategory={CATEGORIES.PAGE_SHARING}
                    analyticsAction={ACTIONS.REGION_LINKEDIN_CLICKED}
                >
                    <LinkedinShareButton url={currentUrl} title={pageTitle} summary={pageTitle}>
                        <LinkedinIcon size={32} round />
                    </LinkedinShareButton>
                </ButtonBox>
                <ButtonBox
                    hint={t('regionHeader.tooltipShareOnEmail')}
                    analyticsCategory={CATEGORIES.PAGE_SHARING}
                    analyticsAction={ACTIONS.REGION_EMAIL_CLICKED}
                >
                    <EmailShareButton url={currentUrl} subject={pageTitle}>
                        <EmailIcon size={32} round bgStyle={{ fill: theme.palette.primary.main }} />
                    </EmailShareButton>
                </ButtonBox>
                <ButtonBox
                    hint={copyTooltip}
                    analyticsCategory={CATEGORIES.PAGE_SHARING}
                    analyticsAction={ACTIONS.REGION_COPY_URL_CLICKED}
                >
                    <RoundButton onClick={() => { copy(currentUrl); setTooltipDone(); }}>
                        <ContentCopy fontSize="small" />
                    </RoundButton>
                </ButtonBox>
                <ButtonBox
                    hint={t('regionHeader.tooltipPrintPage')}
                    analyticsCategory={CATEGORIES.PAGE_SHARING}
                    analyticsAction={ACTIONS.REGION_PRINT_CLICKED}
                >
                    <RoundButton onClick={() => { window.print(); }}>
                        <PictureAsPdf fontSize="small" />
                    </RoundButton>
                </ButtonBox>
            </Box>
        </Box>
    );
};

RegionTitleHeader.propTypes = {
    regionName: PropTypes.string,
};

export default RegionTitleHeader;
