import 'mapbox-gl/dist/mapbox-gl.css';

import { useEffect } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import ReactGA from 'react-ga4';

import { BrowserRouter, useLocation } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import store from './store/store';
import Router from './routes';
import ThemeProvider from './theme';

import servicesContext from './servicesContext';
import { ServicesContext } from './context';

const ViewTracker = () => {

    const location = useLocation();
    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: location.pathname });
    }, [location]);

    return (<></>);
};

export default function App() {


    return (
        <ReduxProvider store={store}>
            <ServicesContext.Provider value={servicesContext}>
                <HelmetProvider>
                    <BrowserRouter>
                        <ViewTracker />
                        <ThemeProvider>
                            <Router />
                        </ThemeProvider>
                    </BrowserRouter>
                </HelmetProvider>
            </ServicesContext.Provider>
        </ReduxProvider>
    );
}
